.about__us__our__leader_container {
  display: flex;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5em;
  background-color: #3cb149;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  margin-top: 6em;
}
.about__us__our__leader_container__overlay {
  width: 50%;
}
.about__us__our__leader_container__heading {
  width: 60%;
  color: white;
}
.about__us__our__leader__container__heading__content__heading__para {
  padding-top: 2em;
  font-size: 20px;
}
.about__us__our__leader_container__heading__content__heading {
  font-size: 25px;
  font-weight: 600;
  width: 50%;
  margin-top: 10px;
}
.about__us__our__leader_container__heading__content__heading span {
  margin-left: 1px;
  border-bottom: 5px solid #fcca25;
}
.about__us__our__leader_container__heading__button {
  margin-top: 3em;
}
.about__us__our__leader_container__heading__button button {
  height: 40px;
  width: 100px;
  border-radius: 50px;
  border: none;
  background-color: #fcca25;
}
.about__us__our__leader_container__heading__button :hover {
  cursor: pointer;
  background-color: #dba800;
}
.howitworks {
  background: linear-gradient(
    to bottom right,
    #3cb149 10%,

    #000000 90%
  );
  padding: 4em 2em;
}
.howitworks__content {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}
.howitworks__content__content {
  display: flex;
  justify-content: center;
  gap: 2em;
  width: 100%;
}
.howitworks__content__content__left,
.howitworks__content__content__right {
  width: 50%;
}
.howitworks__content__content__left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.howitworks__content__content__left img {
  height: auto;
  width: 450px;
}
.howitworks__content__content__right {
  display: flex;
  align-items: center;
}
.howitworks__content__content__right__tabs {
  display: flex;
  flex-direction: column;

  width: 100%;
}
.howitworks__content__content__right__tabs__tab {
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  padding: 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.howitworks__content__content__right__tabs__tab.active {
  background-color: #3cb149;
}
.howitworks__content__content__right__tabs__tab.active
  .howitworks__content__content__right__tabs__tab__step {
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
}
.howitworks__content__content__right__tabs__tab.active
  .howitworks__content__content__right__tabs__tab__info {
  color: rgb(235, 235, 235);
  display: block;
  animation: fade__in 1s ease-in-out forwards;
}
.howitworks__content__content__right__tabs__tab__step {
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 5px;
  padding: 5px 10px;
}
.howitworks__content__content__right__tabs__tab__heading {
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.howitworks__content__content__right__tabs__tab__info {
  text-align: left;
  margin-top: 0.5em;
  color: rgb(70, 70, 70);
  display: none;
}

.about__us__our__foundation__container {
  height: 70%;
  width: 100%;
  margin-top: 6em;
}
.about__us__our__foundation__container {
  position: absolute;
  top: 0;
  left: 0;
}
.about__us__our__foundation__container__main {
  top: 0;
  left: 0;
  padding: 6em;
  max-width: 1440px;
  margin: 0 auto;
}
.about__us__our__foundation__container_heading {
  font-size: 30px;
  font-weight: 500;
  border-bottom: 5px solid #fcca25;

  width: 20%;
}
.about__us__our__foundation__container img {
  width: 100%;
  height: 100%;
}

.about__us__our__foundation__container_heading__two {
  font-size: 40px;
  font-weight: 500;
  color: #3cb149;
  margin-top: 0.5em;
}
.about__us__our__foundation__container__main {
  display: flex;
  justify-content: center;
  width: 100%;
}
.about__us__our__foundation__container__main__two {
  background-color: white;
  padding: 6em;
  margin-left: -8em;
  border-radius: 20px;
  margin-top: -3em;
  width: 60%;
}
.about__us__our__foundation__all__containers {
  position: relative;
  margin-top: 6em;
}
.about__us__our__foundation__all__containers img {
  height: 800px;
  width: 100%;
}
.about__us__our__foundation__container__para {
  width: 90%;
  margin-top: 2em;
  font-size: 18px;
}
.about__us__our__foundation__container__content img {
  width: 90%;
  height: 90%;
}
.satisfied__coustomer__main__container {
  max-width: 1440px;
  margin: 0 auto;
}
.satisfied__coustomer__main__container {
  padding: 6em 3em;
}
.satisfied__coustomer__main__container__heading {
  font-size: 30px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
.satisfied__coustomer__main__container__heading span {
  margin-left: 0.2em;
  border-bottom: 5px solid #fcca25;
  width: fit-content;
}
.satisfied__coustomer__main__container__heading__img {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}
.satisfied__coustomer__main__container__para {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 17px;
  text-align: center;
  margin-top: 2em;
}
.satisfied__coustomer__main__container__para__end {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
}
.Best__services__coustomer__container__main {
  width: 100%;
}
.Best__services__coustomer__container__heading {
  font-size: 30px;
  font-weight: 500;
}
.Best__services__coustomer__container__heading__two span {
  font-size: 35px;
  font-weight: 700;
  background-color: #3cb149;
  color: white;
  margin-top: 0.5em;
  width: 60%;
  padding-left: 2px;
}
.Best__services__coustomer__container__heading__three {
  font-size: 35px;
  font-weight: 400;
  margin-top: 0.5em;
}
.Best__services__coustomer__container__para {
  margin-top: 1em;
  align-items: center;
  width: 80%;
  font-size: 18px;
}

.Best__services__coustomer__container {
  display: flex;
  padding: 8em;
  max-width: 1440px;
  justify-content: space-around;
  margin: 0 auto;
}
.Best__services__coustomer__container__img {
  width: 50%;
}
.Best__services_container__heading__button {
  margin-top: 3em;
}
.Best__services_container__heading__button button {
  height: 40px;
  width: 150px;
  border-radius: 50px;
  border: none;
  background-color: #fcca25;
  color: white;
  font-size: 15px;
}
.Best__services_container__heading__button :hover {
  cursor: pointer;
  background-color: #dba800;
}

//interactive bubble background

@keyframes fade__in {
  from {
    display: none;
  }
  to {
    display: block;
  }
}
@media (max-width: 1250px) {
  .about__us__our__leader_container {
    flex-direction: column;
    width: 100%;
    padding: 5em 3em;
  }
  .about__us__our__leader_container__overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .about__us__our__leader_container__overlay img {
    width: 600px;
  }
  .about__us__our__leader_container__heading__content__heading {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .about__us__our__leader_container__heading {
    width: 90%;
    margin-top: 4em;
  }
}

@media (max-width: 1000px) {
  .howitworks__content__content {
    gap: 4em;
  }
}
@media (max-width: 768px) {
  .howitworks__content__content {
    flex-direction: column;
  }
  .howitworks__content__content__left,
  .howitworks__content__content__right {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .about__us__our__leader_container__overlay img {
    width: 100%;
  }
  .about__us__our__leader__container__heading__content__heading__para {
    width: 100%;
    max-width: 400px;
  }
  .about__us__our__leader_container__heading__button {
    display: flex;
    justify-content: center;
  }
  .howitworks__content__content__left img {
    height: auto;
    width: 100%;
  }
}
@media (max-width: 1080px) {
  .Best__services__coustomer__container {
    flex-direction: column;
    padding-left: 5em;
  }
  .Best__services__coustomer__container__img {
    margin-top: 1.5em;
  }
  .Best__services__coustomer__container__img img {
    width: 100%;
  }
}
@media (max-width: 1240px) {
  .about__us__our__foundation__container__main__two {
    width: 70%;
    padding: 3em;
  }
  .about__us__our__foundation__container__content {
    width: 60%;
  }
}
@media (max-width: 1176px) {
  .about__us__our__foundation__container__main__two {
    width: 80%;
  }
}
@media (max-width: 1120px) {
  .about__us__our__foundation__container__main__two {
    width: 90%;
  }
}
@media (max-width: 1065px) {
  .about__us__our__foundation__container__main__two {
    width: fit-content;
  }
  .about__us__our__foundation__container__content {
    width: 100%;
  }
  .about__us__our__foundation__container__main {
    width: 100%;
  }
  .about__us__our__foundation__container__para {
    margin: 0;
    width: 90%;
    margin-top: 1em;
    font-size: 15px;
  }
  .about__us__our__foundation__all__containers > img {
    height: 1100px;
  }
  .satisfied__coustomer__main__container__heading {
    flex-direction: column;
  }
  .Best__services__coustomer__container__img {
    width: 100%;
  }
}
@media (max-width: 920px) {
  .about__us__our__foundation__container__main {
    flex-direction: column;
  }
  .about__us__our__foundation__container__content {
    height: 50%;
    width: 50%;
    margin-left: 10em;
  }
  .about__us__our__foundation__container__content img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-top-right-radius: 20px;
  }
  .about__us__our__foundation__container__main {
    padding: 0;
    padding-left: 4em;
    padding-bottom: 4em;
  }
  .about__us__our__foundation__container__main__two {
    margin-left: 0.1em;
    margin-right: 4em;
    padding: 2em;
    margin-bottom: 5em;
  }
}
