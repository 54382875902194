// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.nunito-font {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  position: relative;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}
.rdrDayToday .rdrDayNumber span::after {
  background-color: #3cb149 !important;
}
.rdrDateRangePickerWrapper {
  width: fit-content !important;
}
button,
a {
  white-space: nowrap;
}
button:hover,
a:hover {
  transition: 300ms ease-in-out;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #3cb149;
  border-radius: 5px;
}
.header {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 9999;
}
.header__content {
  width: 100%;
  max-width: 1440px;
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0em auto;
}
.header__content__logo__img {
  width: 60px;
}
.header__content__nav {
  display: flex;
  align-items: center;
}
.header__content__nav__links {
  display: flex;
  align-items: center;
}
.header__content__nav__link {
  position: relative;
  margin-left: 1em;
}
.header__content__nav__link__input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.header__content__nav__link__content {
  padding: 0.6em 1.5em;
  color: black;
  border-radius: 50px;
}
.header__content__nav__link__input:hover ~ .header__content__nav__link__content,
.header__content__nav__link__input:checked
  ~ .header__content__nav__link__content {
  transition: 300ms ease-in-out;
  background-color: #ffd857;
}
.header__content__nav__buttons {
  display: flex;
  align-items: center;
}
.header__content__nav__buttons__button {
  all: unset;
  padding: 0.6em 1.8em;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid transparent;
  margin-left: 1em;
  cursor: pointer;
}
.header__content__nav__buttons__button__primary {
  border-color: #242424;
}
.header__content__nav__buttons__button__primary:hover {
  transition: 300ms ease-in-out;
  background-color: #3cb149;
  color: white;
  border-color: #3cb149;
}
.header__content__nav__buttons__button__secondary {
  background-color: #3cb149;
  color: white;
  border-color: #3cb149;
}
.header__content__nav__buttons__button__secondary:hover {
  transition: 300ms ease-in-out;
  background-color: #ffd857;
  color: black;
  border-color: #ffd857;
}
.header__content__nav__buttons__button__menu {
  background-color: #ffd857;
  padding: 0.5em;
  display: none;
  align-items: center;
  justify-content: center;
  color: balck;
}
.header__content__nav__buttons__button__menu:hover {
  transition: 300ms ease-in-out;
  background-color: #3cb149;
  color: white;
}
.login__container {
  position: relative;
  height: 900px;
  background: url("https://images.pexels.com/photos/9158760/pexels-photo-9158760.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")
    no-repeat;
  background-size: cover;
}
.login__container__img {
  width: 100%;
  height: 100%;
}
.login__container__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(#f0f0f0, 0.78);
}
.login__container__overlay__content {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0em auto;
  padding: 2em;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.login__container__overlay__left {
  flex: 1;
}
.login__container__overlay__left__heading {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.login__container__overlay__left__heading div {
  margin: 0.2em 0em;
}
.login__container__overlay__left__heading span {
  font-weight: 500;
}
.login__container__overlay__left__heading span:first-child {
  font-weight: bold;
  color: #3cb149;
}
.login__container__overlay__left__info {
  width: 100%;
  max-width: 550px;
}
.login__container__overlay__right {
  flex: 1;
  max-width: 450px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 3em 2em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__container__overlay__right__heading {
  font-size: 25px;
  margin-bottom: 1.5em;
}
.login__container__overlay__right__heading span {
  font-weight: bold;
}
.auth__input {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 50px;
  height: 45px;
  margin-bottom: 1em;
  overflow: hidden;
  border: 1px solid transparent;
}
.auth__input__icon {
  width: 50px;
  margin-right: -0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth__input__field {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
}
.auth__input:hover {
  transition: 300ms ease-in-out;
  border-color: #fcca25;
}
.auth__input:focus-within {
  transition: 300ms ease-in-out;
  border-color: #fcca25;
}
.login__container__overlay__right__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0em 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.checkbox__content__label {
  font-weight: normal !important;
}
.checkbox__content__label a {
  color: #3cb149;
}
.login__container__overlay__right__row__link {
  color: #292828;
}

.login__container__overlay__right__row__link:hover {
  transition: 300ms ease-in-out;
  color: #3cb149;
}
.login__container__overlay__right__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.login__container__overlay__right__wrapper .auth__input:first-child {
  margin-right: 1em;
}
.login__container__overlay__right__button {
  width: 100%;
  color: white;
  background-color: #fcca25;
  height: 40px;
  border-radius: 50px;
  border: none;
  max-width: 250px;
  margin-top: 2em;
  margin-bottom: 2em;
  cursor: pointer;
}
.login__container__overlay__right__button:hover {
  transition: 300ms ease-in-out;
  background-color: #3cb14a;
}
.login__container__overlay__right__button__reverse {
  width: 100%;
  color: white;
  background-color: #242424;
  height: 40px;
  border-radius: 50px;
  border: none;
  width: fit-content;
  text-align: center;
  padding: 0em 2em;
  margin-top: 1em;
  margin-bottom: 2em;
  cursor: pointer;
}
.login__container__overlay__right__button__reverse:hover {
  transition: 300ms ease-in-out;
  background-color: #3cb14a;
}
.login__container__overlay__right__socials {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 150px;
  justify-content: space-between;
}
.login__container__overlay__right__socials__entry {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.login__container__subscription {
  width: 100%;
  max-width: 800px;
  background-color: #3cb14ade;
  margin: 0em auto;
  margin-top: -5em;
  position: relative;
  padding: 3em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__container__subscription__heading {
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.login__container__subscription__info {
  text-align: center;
  color: white;
  margin-bottom: 1em;
}
.login__container__subscription__row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
}
.login__container__subscription__row__form {
  display: flex;
  align-items: center;
  margin-right: 1em;
  height: 40px;
  background-color: white;
  border-radius: 50px;
  overflow: hidden;
  flex: 1;
}
.login__container__subscription__row__form__input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0em 1.5em;
}
.login__container__subscription__row__form__button {
  background-color: #fcca25;
  color: white;
  height: 100%;
  border-radius: 50px;
  border: none;
  padding: 0em 2em;
  cursor: pointer;
}
.login__container__subscription__row__form__button:hover {
  transition: 300ms ease-in-out;
  background-color: #242424;
}
.login__container__subscription__row__button {
  background-color: #242424;
  color: white;
  height: 40px;
  border-radius: 50px;
  border: none;
  padding: 0em 2em;
  cursor: pointer;
}
.login__container__subscription__row__button:hover {
  transition: 300ms ease-in-out;
  background-color: #fcca25;
}
.home__jumbotron {
  width: 100%;
  position: relative;
  height: 700px;
}
.home__jumbotron__img {
  width: 100%;
  height: 100%;
  // transform: scaleX(-1);
}
.home__jumbotron__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.home__jumbotron__overlay__content {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding: 2em;
  margin: 0em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1em;
}

.home__jumbotron__overlay__content__heading {
  font-size: 65px;
  color: black;
  font-weight: bold;
  width: 70%;
}
.home__jumbotron__overlay__content__info {
  width: 100%;
  max-width: 500px;
  font-size: 18px;
}
.home__jumbotron__overlay__content__form {
  display: flex;
  align-items: center;
  gap: 0.5em;
  background-color: white;
  height: 70px;
  border-radius: 35px;
  padding: 0em 0.5em;
  position: relative;
}
.home__jumbotron__overlay__content__form button {
  background-color: transparent;
  border: none;
  display: flex;
  gap: 2em;
  align-items: center;
  padding: 0em 1em;
  cursor: pointer;
}
.home__jumbotron__overlay__content__form__type {
  color: #8a8a8a;
  height: 100%;
}
.home__jumbotron__overlay__content__form__type span {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home__jumbotron__overlay__content__form__cta {
  background-color: #3cb149 !important;
  color: white;
  height: 50px;
  border-radius: 30px;
  font-weight: 600;
  border: 1px solid transparent !important;
  transition: all 0.3s ease-in-out;
}
.home__jumbotron__overlay__content__form__cta:hover {
  border: 1px solid #3cb149 !important;
  background-color: transparent !important;
  color: #3cb149 !important;
}
.home__jumbotron__overlay__content__form__timeline {
  height: 100%;
}
.home__jumbotron__overlay__content__form__dialog {
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  padding-top: 1em;
  position: absolute;
  width: 470px;
  height: 250px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  top: 110%;
  left: 0;
}
.date__range {
  height: fit-content;
  width: fit-content;
  overflow: hidden;
  left: 14%;
  top: -200%;
}
.home__jumbotron__overlay__content__form__dialog__search {
  height: 40px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #8a8a8a;
  margin: 0em 1em;
}
.home__jumbotron__overlay__content__form__dialog__search:focus-within {
  border-color: #3cb14a;
}
.home__jumbotron__overlay__content__form__dialog__search input {
  height: 100%;
  border-radius: 10px;
  width: 100%;
  border: none;
  padding: 0em 1em;
}
.home__jumbotron__overlay__content__form__dialog__list {
  all: unset;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  height: calc(100% - 2em - 40px);
  padding: 0em 1em;
  overflow-x: hidden;
}
.home__jumbotron__overlay__content__form__dialog__list__item {
  list-style: none;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.home__jumbotron__overlay__content__form__dialog__list__item:hover {
  cursor: pointer;

  background-color: #3cb14a1c;
}
.home__jumbotron__overlay__content__form__dialog__list__item__button {
  all: unset !important;
  height: 100%;
  width: 100% !important;
  padding: 0.5em 1em !important;
  text-align: left !important;
}
.home__sale {
  width: 100%;
  max-width: 1440px;
  padding: 4em 2em;
  margin: 0em auto;
  margin-top: 2em;
}
.home__sale__entry * {
  margin: 0;
}
.home__sale__entry {
  display: flex;
  background-color: #3cb14a27;
  flex-direction: column;
  gap: 1em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1em;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.home__sale__entry__header {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}
.home__sale__entry__header__name {
  font-size: 18px;
  font-weight: 600;
}
.home__sale__entry__header__subject,
.home__sale__entry__header__rating {
  color: rgba(0, 0, 0, 0.758);
  display: flex;
  align-items: center;
  gap: 0.3em;
}
.fill__symbol {
  color: #3cb149;
}
.home__sale__entry:hover {
  background-color: #3cb149;
  color: white;
  .home__sale__entry__header__subject,
  .home__sale__entry__header__rating {
    color: rgba(255, 255, 255, 0.758);
  }
  .fill__symbol {
    fill: white !important;
  }
}
.home__sale__navigation__btns {
  display: flex;
  justify-content: flex-end;
  gap: 2em;
}
.home__sale__navigation__btns button {
  all: unset;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #3cb149;
  border: 1px solid #3cb149;
  transition: all 0.3s ease-in-out;
}
.home__sale__navigation__btns button:hover {
  background-color: #3cb149;
  color: white;
}
.home__expert__entry {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  padding: 1em;
}
.home__expert__entry__expertise {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}

.home__expert__entry__info {
  display: flex;
  gap: 1em;
  align-items: center;
}
.home__expert__entry__info__pic img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.home__expert__entry__info__text__name {
  font-size: 26px;
  font-weight: 600;
}
.home__expert__entry__info__text__orders {
  display: inline-flex;
  align-items: center;
  margin-right: 1em;
  gap: 0.3em;
}
.home__expert__entry__info__text__rating {
  display: inline-flex;
  align-items: center;
  gap: 0.3em;
  svg {
    color: #ffd857;
  }
}
.home__expert__entry__expertise span,
.home__expert__entry__review__heading {
  font-size: 18px;
  font-weight: 600;
}
.home__expert__entry__expertise__item {
  background-color: #3cb14a69;
  font-weight: normal !important;
  padding: 0.5em 1em;
  border-radius: 10px;
  font-size: 14px !important;
}
.home__expert__entry__review {
  color: #545454;
  font-size: 18px;
  font-weight: 400;
}
.home__expert__entry__review__btn {
  all: unset;
  cursor: pointer;
  background-color: transparent;
  height: 40px;
  border-radius: 30px;
  border: 1px solid #3cb149;
  color: #3cb149;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.home__expert__entry__review__btn:hover {
  background-color: #3cb149;
  color: white;
}
.mySwiper {
  padding: 2em 0em !important;
}
.home__sale__entry__img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  mix-blend-mode: luminosity;
}
.home__solutions {
  width: 100%;
  max-width: 1440px;
  padding: 6em 2em;
  margin: 0em auto;
}
.home__solutions__heading {
  font-size: 30px;
  color: black;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}
.home__solutions__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0em -1em;
}
.home__solutions__content__entry {
  width: 30%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
.home__solutions__content__entry:nth-child(2)::before {
  position: absolute;
  content: "";
  height: 50px;
  width: 100px;
  background-image: url("https://cdnx.myassignmenthelp.com/assets/homepage/arrow-how-it-work.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: -65px;
  top: 4em;
}
.home__solutions__content__entry:nth-child(2)::after {
  position: absolute;
  content: "";
  height: 50px;
  width: 100px;
  background-image: url("https://cdnx.myassignmenthelp.com/assets/homepage/arrow-how-it-work.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: -65px;
  top: 4em;
}
.home__solutions__content__entry__icon {
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  width: 150px;
  height: 150px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3cb149;
  margin-bottom: 2em;
}
.home__solutions__content__entry__icon svg {
  width: 80px;
  height: 80px;
}
.home__solutions__content__entry__text {
  color: black;
  font-size: 16px;
}
.home__solutions__content__entry__info {
  font-size: 12px;
  max-width: 100%;
  color: black;
  white-space: wrap;
  text-align: center;
  margin-top: 0.5em;
}
.home__solutions__content__entry:hover .home__solutions__content__entry__icon {
  transition: 300ms ease-in-out;
  background-color: #3cb149;
  color: white;
}
.home__solutions__content__entry__active
  .home__solutions__content__entry__icon {
  transition: 300ms ease-in-out;
  background-color: #3cb149;
  color: white;
}
.home__care {
  width: 100%;
  max-width: 1440px;
  padding: 2em 2em;
  margin: 0em auto;
  margin-top: 1em;
}
.home__care__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
}
.home__care__header__heading {
  font-size: 30px;
  color: black;
  font-weight: bold;
  margin-bottom: 0.5em;
  position: relative;
}
.home__care__header__heading::after {
  position: absolute;
  bottom: 0px;
  right: 0px;
  content: "";
  width: 33%;
  height: 3px;
  background-color: #3cb149;
}
.home__care__header__info {
  font-size: 16px;
}
.home__care__header__info a {
  color: #242424;
}
.home__care__header__info a:hover {
  transition: 300ms ease-in-out;
  color: #3cb149;
}
.home__care__content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0em -1em;
  gap: 2em;
  row-gap: 3em;
}
.home__care__content__entry {
  display: flex;
  flex: 0 1 28%;
  gap: 0.5em;
}
.home__care__content__entry__icon svg {
  height: 60px;
  color: #3cb149;
}
.home__care__content__entry__content {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}
.home__care__content__entry__content__title {
  font-weight: 600;
  font-size: 18px;
}
.home__care__content__entry__content__info {
  font-size: 14px;
  color: #8a8a8a;
}
.home__payments {
  width: 100%;
  position: relative;
  margin: 3.5em 0em;
  margin-bottom: 0em;
  height: 400px;
  overflow: hidden;
}
.home__payments__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home__payments__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2em;
}
.home__payments__overlay__heading {
  font-size: 30px;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5em;
  position: relative;
}
.home__payments__overlay__heading::after {
  position: absolute;
  bottom: 0px;
  right: 0px;
  content: "";
  width: 50%;
  height: 3px;
  background-color: #ffd857;
  text-align: center;
}
.home__payments__overlay__info {
  font-size: 16px;
  color: white;
  text-align: center;
  width: 100%;
  max-width: 800px;
}
.home__payments__overlay__info a {
  color: white;
}
.home__payments__overlay__info a:hover {
  transition: 300ms ease-in-out;
  color: #ffd857;
}
.home__industry {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 2em;
  margin: 0em auto;
  align-items: center;
}
.home__industry__heading {
  font-size: 40px;
  color: black;
  font-weight: bold;
  margin-bottom: 0.5em;
  width: fit-content;
  position: relative;
  margin-bottom: 1.5em;
}
.home__industry__heading span {
  border-bottom: 3px solid #3cb149;
}
// .home__industry__heading::after {
//   position: absolute;
//   bottom: 0px;
//   right: 0px;
//   content: "";
//   width: 55%;
//   height: 3px;
//   background-color: #3cb149;
// }
.home__industry__content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.home__industry__content__entry {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.home__industry__content__entry__img {
  width: 100%;
  height: 280px;
}
.home__industry__content__entry__content {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
}
.home__industry__content__entry__content__title {
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.home__industry__content__entry__content__title span {
  color: #3cb149;
  border-bottom: 3px solid #fcca25;
}
.home__industry__content__entry__content__info {
  display: flex;
  flex-direction: column;
}
.home__industry__content__entry__content__info span {
  margin-bottom: 0.2em;
}
.home__industry__content__entry:nth-child(2) {
  flex-direction: column-reverse;
}
.home__join__team {
  width: 100%;
  position: relative;
  height: 500px;
  margin: 4em 0em;
}
.home__join__team__img {
  width: 100%;
  height: 100%;
}
.home__join__team__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
}
.home__join__team__overlay__slogan {
  font-size: 20px;
  color: #ffd857;
  font-weight: bold;
  text-align: center;
}
.home__join__team__overlay__sub__heading {
  font-size: 30px;
  color: black;
  margin: 0.3em 0em;
  text-align: center;
}
.home__join__team__overlay__heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 1em;
  text-align: center;
}
.home__join__team__overlay__heading span {
  color: #3cb149;
}
.home__join__team__overlay__info {
  width: 100%;
  max-width: 800px;
  text-align: center;
  font-size: 16px;
}
.home__faq {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0em auto;
  padding: 3em 2em;
  padding-top: 1em;
}
.home__faq__col {
  flex: 1;
  padding: 0em 1em;
}
.home__faq__col:nth-child(2) {
  min-width: 70%;
}
.home__faq__col__entry {
  width: 100%;
  margin-bottom: 1em;
}
.home__faq__col__entry__header {
  width: 100%;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  background-color: #efefef;
  border: none;
  padding: 1em 2em;
  border-radius: 50px;
  cursor: pointer;
}
.home__faq__col__entry__header__heading {
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  text-align: left;
  white-space: wrap;
}
.home__faq__col__entry__header__icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0em;
  border-radius: 50px;
}
.home__faq__col__entry__header:hover {
  background-color: #ffd857;
}
.home__faq__col__entry__content {
  padding: 1.5em;
}
.home__faq__col__heading {
  font-size: 30px;
  color: black;
  font-weight: bold;
  position: relative;
  width: fit-content;
  margin-bottom: 0.5em;
}
.home__faq__col__heading::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0em;
  width: 100%;
  height: 3px;
  background-color: #ffd857;
}
.about__jumbotron {
  width: 100%;
  height: 700px;
  position: relative;
  overflow: hidden;
  background: url("https://images.pexels.com/photos/9052869/pexels-photo-9052869.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")
    no-repeat;
  background-size: cover;
  background-position: 20% center;
}
.about__jumbotron__img {
  width: 100%;
  height: 100%;
}
.about__jumbotron__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: rgba(#fefefe, 0.786);
}
.about__jumbotron__overlay__heading {
  font-size: 35px;
  font-weight: bold;
  color: black;
  margin-bottom: 0.5em;
  text-align: center;
}
.about__jumbotron__overlay__info {
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  text-align: center;
  color: black;
  margin-bottom: 1.5em;
}
.about__jumbotron__overlay__buttons {
  display: flex;
  align-items: center;
}
.about__jumbotron__overlay__buttons__link {
  height: 38px;
  padding: 0em 2em;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0em 0.5em;
}
.about__jumbotron__overlay__buttons__link__primary {
  background-color: #3cb149;
  color: white;
}
.about__jumbotron__overlay__buttons__link__primary:hover {
  background-color: #ffd857;
  color: black;
}
.about__jumbotron__overlay__buttons__link__secondary {
  background-color: black;
  color: white;
}
.about__jumbotron__overlay__buttons__link__secondary:hover {
  background-color: #ffd857;
  color: black;
}
.about__concepts {
  margin: 0em auto;
  width: 100%;
  max-width: 1440px;
  padding: 0em 2em;
  margin-top: 5em;
  display: flex;
}
.about__concepts__img {
  width: 50%;
}
.about__concepts__content {
  width: 50%;
  padding: 0em 3em;
}
.about__concepts__content__heading {
  font-size: 25px;
  margin-bottom: 1em;
  font-weight: bold;
  color: black;
  width: 100%;
  max-width: 450px;
}
.about__concepts__content__info {
  width: 100%;
  max-width: 500px;
  font-size: 16px;
}
.about__concepts__content__list {
  display: flex;
  flex-direction: column;
}
.about__concepts__content__list__item {
  margin-left: 2em;
  position: relative;
  font-size: 15px;
  margin-bottom: 0.5em;
}
.about__concepts__content__list__item::after {
  content: "";
  position: absolute;
  top: 5px;
  left: -2em;
  width: 10px;
  height: 10px;
  background-color: #3cb149;
  border-radius: 50px;
}
.about__keyfactors {
  flex-direction: row-reverse;
}
.about__keyfactors .about__concepts__content {
  padding-left: 0em;
}
.benefits__features {
  width: 100%;
  max-width: 1440px;
  padding: 2em;
  margin: 0em auto;
  margin-top: -10em;
  position: relative;
  z-index: 50;
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 0.5em;
  margin-bottom: 6em;
}
.benefits__features__entry {
  width: 32%;
  padding: 2em;
  margin-bottom: 1em;
  border-radius: 20px;
}
.benefits__features__entry__icon {
  color: #3cb149;
  background-color: #fff7dc;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}
.benefits__features__entry__icon svg {
  width: 25px;
  height: 25px;
}
.benefits__features__entry__heading {
  font-size: 20px;
  margin: 0.7em 0em;
  font-weight: bold;
}
.benefits__features__entry__info {
  width: 100%;
}
.benefits__features__entry:hover {
  transition: 300ms ease-in-out;
  background-color: #3cb149;
}
.benefits__features__entry:hover .benefits__features__entry__heading {
  color: white;
}
.benefits__features__entry:hover .benefits__features__entry__info {
  color: white;
}
.products__jumbotron {
  padding: 4em 0em;
  width: 100%;
  padding-top: 10em;
  background-color: #f8f8f8;
}
.products__jumbotron__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0em 2em;
}
.products__jumbotron__header__heading {
  font-size: 30px;
  color: black;
  font-weight: bold;
  margin-bottom: 0.3em;
  text-align: center;
}
.products__jumbotron__header__info {
  text-align: center;
  font-size: 16px;
}
.products__jumbotron__content {
  width: 100%;
  max-width: 1200px;
  padding: 2em;
  margin: 0em auto;
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.products__jumbotron__content__entry {
  width: 31%;
  position: relative;
  margin: 1%;
}
.products__jumbotron__content__entry__input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.products__jumbotron__content__entry__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 2.5em;
}
.products__jumbotron__content__entry__content__icon {
  color: #3cb149;
}
.products__jumbotron__content__entry__content__icon svg {
  width: 80px;
  height: 80px;
}
.products__jumbotron__content__entry__content__sub__heading {
  font-size: 16px;
  color: black;
  margin-top: 1em;
}
.products__jumbotron__content__entry__content__heading {
  font-size: 16px;
  color: black;
  font-weight: bold;
}
.products__jumbotron__content__entry__input:hover
  ~ .products__jumbotron__content__entry__content,
.products__jumbotron__content__entry__input:checked
  ~ .products__jumbotron__content__entry__content {
  transition: 300ms ease-in-out;
  background-color: #3cb149;
}
.products__jumbotron__content__entry__input:hover
  ~ .products__jumbotron__content__entry__content
  *,
.products__jumbotron__content__entry__input:checked
  ~ .products__jumbotron__content__entry__content
  * {
  transition: 300ms ease-in-out;
  color: white;
}
.products__buisness__features__heading {
  width: fit-content;
  text-align: center;
  margin: 3em auto;
  font-size: 25px;
  color: black;
  font-weight: bold;
  position: relative;
}
.products__buisness__features__heading::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
  height: 3px;
  background-color: #3cb149;
}
.products__buisness__features {
  width: 100%;
  max-width: 1440px;
  padding: 0em 2em;
  margin: 0em auto;
  display: flex;
}
.products__buisness__features__left {
  width: 40%;
  margin-right: 4em;
}
.products__buisness__features__right {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -1em;
}
.products__buisness__features__right :hover {
  background-color: #3cb149;
  color: white;
}
.products__buisness__features__right
  :hover
  .products__buisness__features__right__entry__header
  svg {
  color: #ffff;
}
.products__buisness__features__right
  :hover
  .products__buisness__features__right__entry__header {
  color: #ffff;
}

.products__buisness__features__right__entry {
  width: 48%;
  margin: 1%;
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 2em;
}
.products__buisness__features__right__entry__header {
  display: flex;
  align-items: center;
  color: #3cb149;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.8em;
}
.products__buisness__features__right__entry__header svg {
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
}
.products__buisness__features__right__entry__info {
  font-size: 14px;
  width: 100%;
  max-width: 300px;
}
.products__buisness__packages {
  width: 100%;
  max-width: 1200px;
  margin: 3em auto;
  padding: 0em 2em;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5em;
}
.products__buisness__packages__entry {
  width: 31%;
  margin: 1%;
  padding: 2em;
  background-color: #efefef;
  border-radius: 10px;
}
.products__buisness__packages__entry__heading {
  font-size: 18px;
  position: relative;
  color: #545454;
  font-weight: bold;
  margin-bottom: 1em;
  width: fit-content;
}
.products__buisness__packages__entry__heading::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0em;
  width: 80%;
  height: 1px;
  background-color: #545454;
}
.products__buisness__packages__entry__info {
  height: 400px;
  font-size: 13px;
}
.products__buisness__packages__entry__price {
  font-size: 18px;
  font-weight: bold;
  color: #3cb149;
  text-align: center;
  margin-bottom: 0.5em;
}
.products__buisness__packages__entry__link {
  text-decoration: underline;
  display: flex;
  align-items: center;
  color: black;
  justify-content: center;
}
.products__buisness__packages__entry__link svg {
  margin-bottom: -0.1em;
}
.products__buisness__packages__entry:nth-child(2) {
  background-color: #fad96d;
}
.products__buisness__packages__entry:nth-child(2) * {
  color: black !important;
}
.products__buisness__packages__entry:nth-child(2) *::after {
  color: black !important;
  background-color: black;
}
.products__buisness__packages__entry:nth-child(3) {
  background-color: #3cb149;
}
.products__buisness__packages__entry:nth-child(3) * {
  color: white !important;
}
.products__buisness__packages__entry:nth-child(3) *::after {
  color: white !important;
  background-color: white;
}

.benifits__safty__content {
  width: 100%;
  max-width: 1440px;
  margin: 0em auto;
}
.benifits__safty__content_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em 0em;
  padding-bottom: 2em;
  font-size: 30px;
  font-weight: bold;
  position: relative;
}
.benifits__safty__content__entries {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
.benifits__safty__content__entries_heading22 {
  margin-top: -15px;
  padding: 6px 0px;
  font-size: 12px;
}
.benifits__safty__content__entries_heading4::before {
  content: " ";
  position: absolute;
  width: 8px;
  height: 7px;
  top: 3px;
  bottom: 2.8em;
  border-radius: 50px;
  background-color: #3cb149;
  left: -15px;
  padding-right: 2px;
}
.benifits__safty__content__entries_heading45::before {
  content: " ";
  position: absolute;
  width: 8px;
  height: 7px;
  top: 3px;
  bottom: 2.8em;
  border-radius: 50px;
  background-color: #3cb149;
  left: -14px;
  padding-right: 2px;
}
.benifits__safty__content__entries_heading45 {
  position: relative;
}

.benifits__safty__content__entries_heading4 {
  display: flex;
  left: -40px;
}

.benifits__safty__content__entries_heading4 {
  position: relative;
}

.benifits__safty__content__entries__entry {
  background-color: #ededed;
  width: 40%;
  position: relative;
  padding: 2em;
  margin: 2em;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  flex-direction: column;
}
.benifits__safty__content__entries_heading {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 20px;
  font-weight: bold;
}
.benifits__safty__content__entries_para {
  width: 95%;
  text-align: center;
}
.benifits__safty__content__entries__entry_svg {
  top: -35px;
  margin: 0 auto;
  background-color: white;
  padding: 1.5em;
  border-radius: 50px;
  position: absolute;
  left: 40%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Readymaid__Smart__Solution {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.Readymaid__Smart__Solution_overlay {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  height: 100%;
  width: 100%;
}
.Readymaid__Smart__Solution_overlay__heading {
  font-size: 26px;
  padding: 5px;
}
.Readymaid__Smart__Solution_pic {
  width: 100%;
  height: 100%;
}

.readymaid_app_card {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
}
.readymaid_app_card_left {
  width: 40%;
  display: flex;
  justify-content: center;
  padding: 20px;
}
.readymaid_app_card_right {
  width: 60%;
}
.readymaid_app_card_left_para {
  width: 75%;
}

.readymaid_app_card_right_card {
  background-color: #efefef;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 999;
  position: relative;
  top: -140px;
  width: 550px;
  padding: 3em;
}
.readymaid_app_card_right_card_heading {
  color: #3cb149;
  font-size: 23px;
}
.readymaid_app_card_right_card_paragraph {
  width: 90%;
  padding: 1em 0em;
}
.readymaid_app_card_right_card_linea span {
  color: #3cb149;
}
.readymaid_app_card_right_card_linea {
  padding: 2px 0px;
  width: 85%;
}
.readymaid_app_card_right_card_website {
  color: #3cb149;
  font-size: 23px;
  padding: 1em 0em;
}
.readymaid_app_card_left_web_para {
  width: 85%;
}
.products__become__resource {
  padding: 8em 2em;
  background: url("https://images.pexels.com/photos/7516391/pexels-photo-7516391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"),
    no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  gap: 2em;
  position: relative;
  z-index: 0;
}
.products__become__resource::before {
  content: "";
  position: absolute;
  top: 0em;
  left: 0em;
  height: 100%;
  width: 100%;
  background-color: #ffffff76;
  z-index: -1;
}
.products__become__resource__form {
  width: 100%;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.678);
  padding: 3em;
  border-radius: 20px;
  margin: 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products__become__resource__sidebar {
  min-width: 320px;
  width: 30%;
  background-color: rgba(255, 255, 255, 0.888);

  padding: 3em 0em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.products__become__resource__sidebar > * {
  padding: 0em 3em;
}
.products__become__resource__sidebar__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.products__become__resource__sidebar__container {
  background-color: #3cb14a70;
  padding: 0.5em 3em;
  display: flex;
  flex-direction: column;
  gap: 0.5;
}
.products__become__resource__sidebar__row__total {
  font-weight: bold;
}
.products__become__resource__sidebar h3 {
  margin: 0;
}
.products__become__resource__form__heading {
  font-size: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.products__become__resource__form__heading span {
  position: relative;
  font-weight: bold;
  width: fit-content;
  margin-bottom: 1em;
}
.products__become__resource__form__heading span::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 3px;
  width: 50%;
  background-color: #3cb14a;
}
.products__become__resource__form__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 1em;
}
.products__become__resource__form__content__input {
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  background-color: white;
  width: 100%;
  border: 1px solid white;
}
.products__become__resource__form__content__input svg {
  margin: 0em 1em;
  margin-left: 1.5em;
}
.products__become__resource__form__content__input__field {
  flex: 1;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}
.products__become__resource__form__content__input:hover,
.products__become__resource__form__content__input:focus-within {
  transition: 300ms ease-in-out;
  border-color: #3cb14a;
}
.products__become__resource__form__content__upload {
  display: flex;
  align-items: center;
  height: 70px;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid black;
  width: 100%;
  position: relative;
}
.products__become__resource__form__content__upload__input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.products__become__resource__form__content__upload__content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products__become__resource__form__content__upload__content svg {
  margin-right: 1em;
}
.products__become__resource__form__text {
  margin: 2em 0em;
}
.products__become__resource__form__text a {
  color: black;
}
.products__become__resource__form__text a:hover {
  transition: 300ms ease-in-out;
  color: #3cb14a;
}
.products__become__resource__form__button {
  padding: 0.9em 2.5em;
  background-color: black;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  border: none;
}
.products__become__resource__form__button:hover {
  transition: 300ms ease-in-out;
  background-color: #3cb14a;
}
.products__get__resource__hire {
  width: 100%;
  max-width: 1440px;
  padding: 0em 2em;
  margin: 0em auto;
  margin-top: 7em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products__get__resource__hire__heading {
  font-size: 25px;
  color: black;
}
.products__get__resource__hire__heading span {
  font-weight: bold;
}
.products__get__resource__hire__buttons {
  margin: 2em 0em;
  background-color: #fad96d;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.products__get__resource__hire__buttons__button {
  background-color: transparent;
  color: black;
  height: 50px;
  padding: 0em 2em;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.products__get__resource__hire__buttons__button:hover {
  transition: 300ms ease-in-out;
  background-color: #3cb14a;
  color: white;
}
.products__get__resource__hire__buttons__button__active {
  transition: 300ms ease-in-out;
  background-color: #3cb14a;
  color: white;
}
.products__get__resource__hire__details {
  width: 100%;
  max-width: 1440px;
  padding: 7em 2em;
  margin: 0em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products__get__resource__hire__details__heading {
  font-size: 25px;
  color: black;
  font-weight: bold;
  margin-bottom: 1em;
}
.products__get__resource__hire__details__content {
  display: flex;
  align-items: center;
}
.products__get__resource__hire__details__content__left {
  flex: 1;
  margin-right: 2em;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  padding: 2em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products__get__resource__hire__details__content__left__heading {
  font-size: 20px;
  color: black;
  font-weight: bold;
  width: fit-content;
  position: relative;
  margin-bottom: 1em;
}
.products__get__resource__hire__details__content__left__heading::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 2px;
  width: 100%;
  background-color: #3cb14a;
}
.products__get__resource__hire__details__content__left__content {
  width: 100%;
  margin-bottom: 0.9em;
}
.products__get__resource__hire__details__content__left__address {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.products__get__resource__hire__details__content__left__address span {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0.5em;
}
.products__get__resource__hire__details__content__right {
  padding: 2em;
  border-radius: 10px;
  flex: 1;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products__get__resource__hire__details__content__right__heading {
  font-size: 20px;
  color: black;
  font-weight: bold;
  width: fit-content;
  position: relative;
  margin-bottom: 1em;
}
.products__get__resource__hire__details__content__right__heading::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 2px;
  width: 100%;
  background-color: #3cb14a;
}
.products__get__resource__hire__details__content__right__content {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
}
.products__get__resource__hire__details__content__right__content::-webkit-scrollbar {
  width: 5px;
}
.products__get__resource__hire__details__content__right__content::-webkit-scrollbar-thumb {
  background-color: black;
}
.products__get__resource__hire__details__content__right__content__entry {
  padding: 2em;
  background-color: #f2fbf8;
  border-radius: 10px;
  display: flex;
  margin-bottom: 1em;
}
.products__get__resource__hire__details__content__right__content__entry__left {
  margin-right: 1em;
  display: flex;
  align-items: center;
}
.products__get__resource__hire__details__content__right__content__entry__left
  img {
  margin-right: 1em;
}
.products__get__resource__hire__details__content__right__content__entry__left__content__heading {
  font-weight: bold;
  margin-bottom: 0.5em;
}
.products__get__resource__hire__details__content__right__content__entry__left__content {
  display: flex;
  flex-direction: column;
}
.products__get__resource__hire__details__content__right__content__entry__right {
  flex: 1;
}
.benifits__resources {
  position: relative;
  width: 100%;
  height: 700px;
}
.benifits__resources__img {
  width: 100%;
  height: 100%;
}
.benifits__resources__overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  z-index: 999;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0em 2em;
  transform: translate(-50%, -50%);
}

.benifits__resources__overlay__content {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin: 1em 1em;
}
.benifits__resources__overlay__content__entry {
  width: 100%;
  height: 250px;
  background-color: #fff;
  padding: 2em;
  border-radius: 15px;
  cursor: pointer;
}
.benifits__resources__overlay__content__entry:hover {
  background-color: #3cb149;
  transition: all 0.3s ease-in-out;
}
.benifits__resources__overlay__content__entry__icon {
  color: #3cb149;
  width: 40px;
  height: 40px;
}
.benifits__resources__overlay__content__entry:hover
  .benifits__resources__overlay__content__entry__icon {
  color: white;
}
.benifits__resources__overlay__content__entry:hover
  .benifits__resources__overlay__content__entry__heading {
  color: #fff;
}
.benifits__resources__overlay__content__entry:hover
  .benifits__resources__overlay__content__entry__info {
  color: #fff;
}
.benifits__resources__overlay__header {
  margin-bottom: 4em;
}
.benifits__resources__overlay__header__heading {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0.5em;
  position: relative;
}
.benifits__resources__overlay__header__heading::after {
  content: "";
  width: 50%;
  height: 3px;
  background-color: #fcca25;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.benifits__resources__overlay__header__info {
  color: #fff;
}
.benifits__resources__overlay__content__entry__heading {
  font-size: 16px;
  font-weight: bold;
  margin: 1em 0em;
  margin-top: 1.5em;
}
.privacy__policy {
  display: flex;
  flex-direction: column;
}
.privacy__policy__header {
  height: 300px;
  background-color: #fad96d;
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
}
.privacy__policy__content {
  width: 100%;
  max-width: 1440px;
  padding: 2em;
  margin: 0em auto;
}
@media (max-width: 1100px) {
  .login__container {
    height: 1200px;
  }
  .login__container__overlay__content {
    flex-direction: column;
    justify-content: center;
  }
  .login__container__overlay__left {
    flex: none;
    width: 100%;
    margin-bottom: 5em;
  }
  .login__container__overlay__right {
    flex: none;
    max-width: 100%;
    width: 100%;
  }
  .products__become__resource__form
    .home__jumbotron__overlay__content__right__card__content__row {
    flex-direction: column;
  }
}
@media (max-width: 1000px) {
  // .home__solutions__content__entry {
  //   width: 23%;
  // }

  .home__industry__content__entry {
    width: 50%;
  }
  .home__payments__img {
    width: 200%;
    margin-left: -50%;
  }
  .home__faq {
    flex-direction: column-reverse;
  }
  .home__faq__col {
    display: flex;
    flex-direction: column;
    padding: 0em;
    align-items: center;
    margin-bottom: 2em;
  }
  .home__faq__col:first-child {
    display: none;
  }
  .about__concepts {
    flex-direction: column;
  }
  .about__concepts__img {
    width: 100%;
  }
  .about__concepts__content {
    padding: 2em 0em;
    padding-bottom: 0em;
    width: 100%;
  }
  .readymaid_app_card {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .readymaid_app_card_right {
    width: 70%;

    max-width: 1440px;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .readymaid_app_card_left {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .login__container__overlay__right__row {
    flex-direction: column;
    align-items: flex-start;
  }
  .login__container__overlay__right__row__link {
    margin-top: 1em;
  }
  .header__content__nav__buttons__button__menu {
    display: flex;
  }
  .header__content__nav__links {
    position: absolute;
    top: 80px;
    right: 2em;
    flex-direction: column;
    background-color: white;
    padding: 1em;
    border-radius: 10px;
    padding-bottom: 0.5em;
    width: 200px;
  }
  .header__content__nav__link {
    margin-left: 0em;
    margin-bottom: 0.5em;
    width: 100%;
    text-align: center;
  }
  .home__jumbotron__img {
    width: 150%;
    margin-left: -40%;
  }
  .about__jumbotron__img {
    width: 120%;
    margin-left: -10%;
  }
  .products__jumbotron__content__entry {
    width: 48%;
  }
  .products__buisness__features {
    flex-direction: column;
  }
  .products__buisness__features__left {
    width: 100%;
    margin-bottom: 2em;
  }
  .products__buisness__features__right {
    width: 100%;
  }
  .products__buisness__packages__entry {
    width: 100%;
  }
  .products__get__resource__hire__details__content {
    flex-direction: column;
  }
  .products__get__resource__hire__details__content__left {
    margin-right: 0em;
    margin-bottom: 2em;
  }
  .benefits__features__entry {
    width: 50%;
  }
  .home__solutions__content__entry:nth-child(2)::before {
    display: none;
  }
  .home__solutions__content__entry:nth-child(2)::after {
    display: none;
  }
}
@media (max-width: 800px) {
  // .home__solutions__content__entry {
  //   width: 31%;
  // }
}
@media (max-width: 768px) {
  .benifits__safty__content__entries__entry {
    width: 100%;
  }
  .readymaid_app_card_right_card_paragraph {
    width: 100%;
    padding: 8px 0px;
  }
  .readymaid_app_card_right_card_linea {
    width: 100%;
    padding: 1px;
  }
  .readymaid_app_card_right_card_website {
    padding: 12px 0px;
  }
  .products__become__resource {
    flex-direction: column;
  }
  .products__become__resource__form,
  .products__become__resource__sidebar {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .login__container__overlay__right__wrapper {
    flex-direction: column;
  }
  .login__container__reverse {
    height: 1400px;
  }
  .login__container__overlay__right__wrapper:first-child {
    margin-right: 0em;
  }

  .home__industry__content__entry {
    width: 100%;
  }
  .home__industry__content__entry:nth-child(2) {
    flex-direction: column;
  }
  .home__industry__content__entry__img {
    height: 200px;
  }
  .home__industry__content__entry__content {
    height: 200px;
    padding: 0em;
  }
  .home__payments__img {
    width: 250%;
    margin-left: -70%;
  }
  .products__get__resource__hire__details__content__right__content__entry {
    flex-direction: column;
  }
  .products__get__resource__hire__details__content__right__content__entry__left {
    margin-right: 0em;
    margin-bottom: 1em;
  }
  .benefits__features__entry {
    width: 100%;
  }
  .home__jumbotron__overlay__content {
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }
  .home__jumbotron__overlay__content__left,
  .home__jumbotron__overlay__content__right {
    width: 100%;
  }
  .home__jumbotron__overlay__content__left {
    justify-content: flex-end;
    gap: 1em;
    height: 50%;
  }
  .home__jumbotron__overlay__content__features {
    display: none;
  }
  .home__jumbotron__overlay__content__heading,
  .home__jumbotron__overlay__content__info {
    margin-bottom: 0px;
  }
  .home__jumbotron__overlay__content__right__card {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .header__content__logo__img {
    width: 50px;
  }
  .home__jumbotron__img {
    width: 200%;
    margin-left: -100%;
  }
  .about__jumbotron__img {
    width: 300%;
    margin-left: -100%;
  }
  .home__jumbotron__overlay__content__heading {
    font-size: 35px;
  }

  // .home__jumbotron__overlay__content {
  //   text-align: center;
  // }
  .home__solutions__content__entry {
    width: 48%;
  }
  .products__jumbotron__content__entry {
    width: 100%;
  }
  .products__buisness__features__right {
    margin: 0em;
  }
  .products__buisness__features__right__entry {
    width: 100%;
  }

  .readymaid_app_card_right_card {
    padding: 2em;
  }
  .readymaid_app_card_right_card_paragraph {
    padding: 1em 0em;
    width: 90%;
  }
  .readymaid_app_card_right_card_linea {
    width: 100%;
  }
  .products__become__resource__form__content__input {
    width: 100%;
  }
  .products__become__resource__form__content__upload {
    width: 100%;
  }
  .home__care__content__entry {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .home__jumbotron__overlay__content__buttons {
    flex-direction: column;
  }
  .home__jumbotron__overlay__content__buttons__link {
    margin-right: 0em;
  }
  .home__solutions__content__entry__icon {
    width: 120px;
    height: 120px;
  }
  .home__solutions__content__entry__icon svg {
    width: 60px;
    height: 60px;
  }
  .home__jumbotron__overlay__content__heading {
    font-size: 26px;
  }
  .home__solutions__content__entry {
    width: 100%;
  }
  .home__care__content__entry {
    flex: 0 1 94%;
    border: 1px solid #3cb149;
    border-radius: 10px;
    padding: 1em;
  }
  .home__care__content {
    row-gap: 2em;
  }
  .products__become__resource__form {
    padding: 1em;
  }
  .products__become__resource__sidebar {
    padding: 1em 0em;
    min-width: fit-content;
  }
  .products__become__resource__sidebar > * {
    padding: 0em 1em;
  }
}
.login__jumbotron {
  background-color: rgba(255, 196, 0, 0.781);
  height: 900px;
  position: relative;
}
.login__jumbotron__img {
  height: 100%;
  width: 100%;
}
.login__jumbotron__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.login__jumbotron__overlay__content {
  width: 100%;
  max-width: 1440px;
  padding: 2em;
  padding-top: 5em;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0em auto;
  justify-content: space-evenly;
}
.login__jumbotron__overlay__left {
  width: 50%;
}
.login__jumbotron__overlay__right {
  width: 37%;
  box-shadow: 0px 1px 1px 1px transparent;
  background-color: rgba(255, 255, 255, 0.452);
  align-items: center;
  border-radius: 10px;
  padding: 3em;
  border-radius: 20px;
}
.login_all_second_container_content_one {
  font-size: 25px;
}
.login_all_second_container_content_two {
  font-size: 35px;
  font-weight: 600;
}
.input {
  height: 30px;
  width: 200px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: #f6f6f6;
  border: none;
  padding-left: 20px;
  flex-direction: row;
  max-width: 1440px;
  margin-right: 5px;
  outline: none;
}
.login_all_second_container_input {
  display: flex;
}
.login_all_second_container_input_two {
  display: flex;
}
.login_all_second_container_input_three {
  display: flex;
}
.login_all_second_container_checkbox span {
  color: #3cb149;
  font-size: 13px;
}
.login_all_second_container_checkbox {
  font-size: 14px;
  width: 100%;
}
.buttones {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: rgb(0, 0, 0);
  color: white;
  cursor: pointer;
}
.login_all_second_container_checkbox {
  padding-top: 1em;
}
.login_all_second_container_button {
  padding-top: 1em;
  display: flex;
  justify-content: end;
}
.login_all_second_container_button_two {
  display: flex;
  justify-content: center;
  padding-top: 1em;
}
.login_all_second_container_svg svg {
  cursor: pointer;
}
.or {
  text-decoration: none;
  color: #3cb149;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.login_all_second_container_svg {
  display: flex;
  justify-content: center;
  padding-top: 1em;
}
.svg_two {
  margin-left: 20px;
  margin-right: 20px;
}
.login_all_first_container_content_two span {
  font-size: 35px;
  font-weight: 700;
}
.login_all_first_container_content_two {
  font-size: 25px;
  font-weight: 400;
}
.login_all_first_container_content_one {
  font-size: 25px;
  font-weight: 400;
}
.login_all_first_container_content_three {
  font-size: 25px;
  font-weight: 400;
}
.login_all_first_container_content_four {
  padding-top: 1em;
  width: 70%;
  align-items: center;
}
.overlay__button {
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.login__jumbotron__buttons {
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  background-color: #4d4c4c;
  height: 180px;
  padding-top: 4em;
  margin-bottom: 2em;
  flex-direction: column;
}
.login__jumbotron__end__content__heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  background-color: #3cb14a;
  padding-bottom: 20px;
  border-radius: 10px;
  z-index: 99;
  margin-top: -250px;
  max-width: 1440px;
  padding-top: 2em;
}
.login__jumbotron__end__content__heading__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login__jumbotron__overlay__button {
  display: flex;
  justify-content: center;
}
.login__jumbotron__end__content__heading_two {
  text-align: center;
  width: 80%;
  color: white;
  padding-bottom: 1em;
}
.login__jumbotron__end__content__heading_one {
  color: white;
  padding-bottom: 1em;
}
.end_input {
  width: 100%;
  max-width: 400px;
  height: 40px;
  border-radius: 20px;
  border: none;
  padding-left: 15px;
  outline: none;
}
.end_button {
  height: 40px;
  border-radius: 20px;
  padding: 0em 2em;
  border: none;
  background-color: #fcca25;
  color: white;
  cursor: pointer;
  text-align: center;
}
.login__jumbotron__end__content__input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login__jumbotron__end__content__input__button {
  width: 500px;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
}
.end__button__two {
  width: 130px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: black;
  color: white;
  margin-left: 10px;
  cursor: pointer;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background: rgba(76, 175, 80, 0.5); /* Light green ripple */
  transform: scale(0);
  animation: ripple-animation 1.5s infinite forwards;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure the ripple does not block clicks */
}

.whatsapp-cta:hover .ripple {
  animation: ripple-animation 1s linear forwards; /* Animate on hover */
}

@keyframes ripple-animation {
  to {
    transform: scale(1.9);
    opacity: 0; /* Fade out */
  }
}
@media (max-width: 931px) {
  .login__jumbotron__overlay__content {
    padding-top: 50px;
    justify-content: start;
  }
}
@media (max-width: 1200px) {
  .login__jumbotron__end__content__input {
    flex-direction: column;
  }
}
@media (max-width: 1000px) {
  .login__jumbotron__overlay__content {
    flex-direction: column;
    width: 100%;
  }
  .login__jumbotron__overlay__content {
    display: flex;
    justify-content: right;
  }
  .login_all_first_container_content_four {
    text-align: center;
  }
  .login__jumbotron__end__content__heading {
    margin-top: 0;
  }
  .login__jumbotron__buttons {
    height: 400px;
  }
}
@media (max-width: 1425px) {
  .login__jumbotron__overlay__right {
    width: 38%;
  }
}
@media (max-width: 1388px) {
  .login__jumbotron__overlay__right {
    width: 39%;
  }
}
@media (max-width: 1353px) {
  .login__jumbotron__overlay__right {
    width: 40%;
  }
  .login_all_second_container_input {
    display: flex;
    justify-content: center;
  }
  .login_all_second_container_input_two {
    display: flex;
    justify-content: center;
  }
  .login_all_second_container_input_three {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1335px) {
  .login__jumbotron__overlay__right {
    width: fit-content;
  }
}
@media (max-width: 1360px) {
  .login__jumbotron__end__content__heading {
    min-width: 70%;
  }
}
@media (max-width: 1205px) {
  .login__jumbotron__end__content__heading {
    min-width: 50%;
  }
  .login__jumbotron__end__content__input__button {
    width: fit-content;
  }
  .login__jumbotron__end__content__heading {
    max-width: 1440px;
  }
  .end__button__two {
    margin-top: 1em;
  }
}
@media (max-width: 1200px) {
  .login__jumbotron__end__content__heading {
    min-width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 930px) {
  .login_all_first_container_content_four {
    width: 100%;
  }
  .login__jumbotron__overlay__right {
    margin: 10px;
  }
}
.signup__jumbotron {
  background-color: #292828;
  height: 900px;
  position: relative;
}
.signup__jumbotron__img {
  height: 100%;
  width: 100%;
}
.signup__jumbotron__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.signup__jumbotron__overlay__content {
  width: 100%;
  max-width: 1440px;
  padding: 2em;
  padding-top: 5em;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0em auto;
  justify-content: space-evenly;
}
.signup__jumbotron__overlay__left {
  flex: 1;
}
.signup_all_first_container_content_one {
  font-size: 25px;
  font-weight: 400;
}
.signup_all_first_container_content_two span {
  font-size: 35px;
  font-weight: 700;
  color: #3cb14a;
}
.signup_all_first_container_content_two {
  font-size: 25px;
  font-weight: 400;
}
.signup_all_first_container_content_three {
  font-size: 25px;
  font-weight: 400;
}
.signup_all_first_container_content_four {
  padding-top: 1em;
  width: 70%;
  align-items: center;
}
.signup__jumbotron__overlay__right {
  flex: 1;
  max-width: 400px;
  box-shadow: 0px 1px 1px 1px transparent;
  background-color: rgba(255, 255, 255, 0.452);
  align-items: center;
  border-radius: 10px;
  padding: 3em;
  border-radius: 20px;
}
.signup_all_second_container_content_one {
  font-size: 25px;
}
.signup_all_second_container_content_two {
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}
.signup_all_second_container_content_two span {
  margin-left: 0.2em;
}
.signup_all_second_container_content_two {
  font-size: 25px;
  font-weight: 500;
}
.signup_all_second_container_input_three {
  display: flex;
  flex-direction: column;
}
.inputs {
  height: 40px;
  width: 400px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: #f6f6f6;
  border: none;
  padding-left: 20px;
  flex-direction: row;
  max-width: 1440px;
  margin-right: 5px;
  outline: none;
}
.signup__jumbotron__overlay__right {
  width: 37%;
  box-shadow: 0px 1px 1px 1px transparent;
  background-color: rgba(255, 255, 255, 0.836);
  align-items: center;
  border-radius: 10px;
  padding: 3em;
  border-radius: 20px;
  height: 60%;
}
.signup_all_second_container_checkbox span {
  color: #3cb149;
  font-size: 13px;
}
.signup_all_second_container_checkbox {
  font-size: 14px;
  width: 100%;
}
.signup_all_second_container_checkbox_main {
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
}
.signup__forgot__password {
  font-size: 15px;
  width: 60%;
}
.forgot__anchor {
  color: #000000;
}
.signup_all_second_container_button {
  padding-top: 2em;
  display: flex;
  justify-content: center;
}
.signup_all_second_container_svg {
  display: flex;
  justify-content: center;
  padding-top: 1.5em;
}
.signup_all_second_container_svg svg {
  cursor: pointer;
}
.signup__jumbotron__buttons {
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  background-color: #4d4c4c;
  height: 180px;
  padding-top: 4em;
  margin-bottom: 2em;
  flex-direction: column;
}
.signup__jumbotron__end__content__heading__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signup__jumbotron__end__content__heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  background-color: #3cb14a;
  padding-bottom: 20px;
  border-radius: 10px;
  z-index: 99;
  margin-top: -250px;
  max-width: 1440px;
  padding-top: 2em;
}
.signup__jumbotron__end__content__heading_one {
  color: white;
  padding-bottom: 1em;
  font-size: 25px;
  font-weight: 500px;
}
.signup__jumbotron__end__content__heading_two {
  text-align: center;
  width: 80%;
  color: white;
  padding-bottom: 1em;
}
.signup__jumbotron__end__content__input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup__jumbotron__end__content__input__button {
  width: 500px;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
}
.signup__jumbotron__overlay__button {
  display: flex;
  justify-content: center;
}
.buttons {
  width: 50%;
  height: 30px;
  border-radius: 20px;
  border: none;
  background-color: rgb(0, 0, 0);
  color: white;
  cursor: pointer;
  background-color: rgb(236, 185, 15);
}
.order__select__control {
  height: 50px !important;
  border-radius: 25px !important;
  min-width: 100% !important;
}
.order__select__indicator-separator {
  display: none !important;
}

.order__select__value-container {
  padding: 0em 1em !important;
}
@media (max-width: 931px) {
  .signup__jumbotron__overlay__content {
    padding-top: 50px;
    justify-content: start;
  }
}
@media (max-width: 1200px) {
  .signup__jumbotron__end__content__input {
    flex-direction: column;
  }
}
@media (max-width: 1000px) {
  .signup__jumbotron__overlay__content {
    flex-direction: column;
  }
  .signup__jumbotron__end__content__heading {
    margin-top: 0;
  }
}
@media (max-width: 1425px) {
  .signup__jumbotron__overlay__right {
    width: 38%;
  }
}
@media (max-width: 1388px) {
  .signup__jumbotron__overlay__right {
    width: 39%;
  }
}
@media (max-width: 1353px) {
  .signup__jumbotron__overlay__right {
    width: 40%;
  }
  .signup_all_second_container_input_three {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1335px) {
  .signup__jumbotron__overlay__right {
    width: fit-content;
  }
}
@media (max-width: 1360px) {
  .signup__jumbotron__end__content__heading {
    min-width: 70%;
  }
}
@media (max-width: 1205px) {
  .signup__jumbotron__end__content__heading {
    min-width: 50%;
  }
  .signup__jumbotron__end__content__input__button {
    width: fit-content;
  }
  .signup__jumbotron__end__content__heading {
    max-width: 1440px;
  }
}
@media (max-width: 1200px) {
  .signup__jumbotron__end__content__heading {
    min-width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 930px) {
  .signup_all_first_container_content_four {
    width: 100%;
  }
  .sigup__jumbotron__overlay__right {
    margin: 10px;
  }
}

@media (max-width: 665px) {
  .Readymaid__Smart__Solution_overlay__para {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
  }
  .readymaid_app_card_right_card_linea {
    padding: 0px 1em;
  }

  .readymaid_app_card_left_para {
    width: 100%;
  }
  .Readymaid__Smart__Solution_overlay__heading {
    font-size: 22px;
  }
  .readymaid_app_card_right_card_linea {
    padding: 0;
  }
  .readymaid_app_card_right_card {
    padding: 2.3em;
  }
  .login__container__subscription__row {
    flex-direction: column;
  }
  .login__container__subscription__row__form {
    min-height: 40px;
    margin-bottom: 1em;
    width: 100%;
  }
  .login__container__subscription__row__form__button,
  .login__container__subscription__row__form__input {
    height: 40px;
  }
  .home__solutions {
    padding: 2em;
  }
  .home__jumbotron__overlay__content__form {
    width: 100%;
    flex-direction: column;
    gap: 1em;
    height: fit-content;
    padding: 1em;
  }
  .home__jumbotron__overlay__content__form__cta,
  .home__jumbotron__overlay__content__form__type,
  .home__jumbotron__overlay__content__form__timeline {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .date__range {
    top: -40%;
  }
  .home__jumbotron__overlay__content__form__cta {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .home__jumbotron__overlay__content__heading {
    width: 100%;
  }
  .home__jumbotron {
    height: 550px;
  }
  .home__jumbotron__img {
    object-fit: cover;
  }
}

@media (max-width: 370px) {
  .Readymaid__Smart__Solution_overlay__heading {
    font-size: 19px;
  }
}
